export const organizationFormFieldNames = {
  NAME: 'NAME',
  DISAMBIGUATING_DESCRIPTION: 'DISAMBIGUATING_DESCRIPTION',
  DESCRIPTION: 'DESCRIPTION',
  WEBSITE: 'WEBSITE',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  LOGO: 'LOGO',
  IMAGE: 'IMAGE',
  LOCATION: 'LOCATION',
  CONTACT_TITLE: 'CONTACT_TITLE',
  CONTACT_WEBSITE: 'CONTACT_WEBSITE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL: 'EMAIL',
  ORGANIZATION_TYPE: 'ORGANIZATION_TYPE',
};

export const personFormFieldNames = {
  NAME: 'NAME',
  DISAMBIGUATING_DESCRIPTION: 'DISAMBIGUATING_DESCRIPTION',
  DESCRIPTION: 'DESCRIPTION',
  WEBSITE: 'WEBSITE',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  IMAGE: 'IMAGE',
  OCCUPATION: 'OCCUPATION',
};
